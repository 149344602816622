import {
  Directive,
  Input,
  HostBinding,
  ElementRef,
  OnChanges,
  SimpleChanges,
  inject,
  Renderer2,
} from '@angular/core';

const baseButtonClass =
  'flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 btn-mango--base';

const regularButtonClass = 'btn-mango--regular';
const disabledButtonClass = 'btn-mango--disabled';

const buttonStatus = ['regular', 'disabled'] as const;
type ButtonType = (typeof buttonStatus)[number];
const ButtonClasses: { [key in ButtonType]: string } = {
  regular: regularButtonClass,
  disabled: disabledButtonClass,
};

const buildTwClasses = (btnType: ButtonType): string =>
  `${baseButtonClass} ${ButtonClasses[btnType]}`;

@Directive({
  selector: 'button[mango-button]',
  standalone: true,
})
export class ButtonDirective {
  @HostBinding('class')
  tailwindClasses = buildTwClasses('regular');

  @Input() set loading(isLoading: boolean) {
    if (isLoading) {
      this.tailwindClasses = buildTwClasses('disabled');
    } else {
      this.tailwindClasses = buildTwClasses('regular');
    }
  }
}
